import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from './main.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [MainService],
})
export class ServicesModule { }
